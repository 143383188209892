<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <div>
        <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
        {{ $route.name }}
      </div>
      <div>
        <div class="subHeadTitle">
          {{
            getProcedureTypes[$route.params.procedureType]
              ? getProcedureTypes[$route.params.procedureType].name
              : ""
          }}
          /
          {{
            centralization
              ? centralization.identifier + " - " + centralization.name
              : ""
          }}
        </div>
        <div
          class="col2_subHead"
          style="margin-top: 1.2rem; display: flex; justify-content: flex-end;"
        >
          <div
            v-if="$route.params.procedureType !== 'achizitie_directa'"
            class="Button1 Btn_center"
            @click="createProcedureDocument"
            style="margin-right: 5px;"
          >
            <div class="icon">
              <img
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">Atribuirea prin procedură </span>
          </div>
          <div class="Button1 Btn_center" @click="openActions">
            <div class="icon">
              <img
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content"> Acțiuni achiziții </span>
          </div>
        </div>
      </div>
    </div>

    <template v-if="PERMISIONS.list">
      <Table
        :key="tableReload"
        :Data="tableData"
        @checkboxManager="checkboxManager"
        @Btn14="viewPdf"
        @Btn4="updateProcedureDocument"
        @Btn5="closeProcedureDocument"
        @previousPage="setPage"
        @nextPage="setPage"
        @fullReload="reloadPage"
        @selectAll="selectAll"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/assignmentsPermMixin.js";
import DialogModal from "@/components/DialogModal";
import AssignmentActions from "@/components/AssignmentActions";
import ProcedureDocuments from "@/components/ProcedureDocuments";
import {
  REFERENCE_PAGINATED,
  GET_PAAP_CENTRALIZATION_PAGINATED,
  PROCEDURE_DOCUMENTS,
} from "@/api.js";
import { mapMutations, mapGetters } from "vuex";
import Table from "@/components/Table";

export default {
  components: {
    Table,
  },

  mixins: [PermisionsMixin],

  data() {
    return {
      dataLoading: false,
      tableData: {
        ready: 1,
        options: {
          searchModule: "REFERENCE_PAGINATED",
          refresh: true,
        },
        head: [
          {
            title: "",
            show: true,
            fixed: {
              left: true,
            },
            checkbox: true,
          },
          {
            title: "NR.",
            sort: true,
            fixed: {
              left: true,
            },
          },
          {
            title: "Denumire",
          },
          {
            title: "Anul de încadrare",
          },

          {
            title: "Structură organizatorică",
          },
          {
            title: "Inițiator",
          },

          {
            title: "Cod CPV (dominant)",
          },
          {
            title: "Valoarea totală estimată, fără TVA",
          },
          {
            title: "Valoarea totală estimată",
          },

          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
          },
        ],
        body: [],
      },
      currentPage: this.getPaginatedParams(),
      totalItemsLength: 0,
      tableFilter: {
        query: "",
        baseQuery: "",
      },
      totalRecords: 0,
      tableReload: 1,
      checkedRows: [],
      centralization: null,
      allSelected: null,
    };
  },
  computed: {
    ...mapGetters(["getProcedureTypes", "getNeedsTableData"]),
  },
  watch: {},
  methods: {
    ...mapMutations(["setReferenceIdForPreview", "setNeedsTableData"]),
    checkboxManager(val) {
      this.checkedRows = val;
    },

    selectAll(val) {
      this.allSelected = val;
    },
    closePanel() {
      this.$router.push(this.$route.path.slice(0, -2));
    },
    viewPdf(item) {
      let data = this.getNeedsTableData;

      this.setReferenceIdForPreview({
        data: {
          id: data[item].id,
        },
        origin: "REFERENCE_GET_PDF",
      });
    },

    prepareData(rows, o = {}) {
      const getReferenceStatusIcon = (s) =>
        ({
          draft: {
            border: "left",
            icon: "far fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2.2rem;",
            tooltip: "Draft",
          },
          closed: {
            border: "left",
            icon: "fas fa-badge-check",
            css: "padding: 0.5rem; font-size:2.2rem; color: green;",
            tooltip: "Finalizat",
          },
        }[s] || s);

      const preparations = [];

      for (const row of rows) {
        let status = "draft";

        if (row.acquisitionProduct.length) {
          status = "closed";
        } else if (row.procedureDocument.length) {
          const procedureDocument = row.procedureDocument[0];
          status = procedureDocument.status;
        }

        let name = "-";
        let cpv = row.need?.product?.cpvCategory?.cpvCode || "-";
        let price = "-";

        if (row.createdByUser) {
          name =
            `${row.createdByUser.last_name || ""} ${row.createdByUser
              .first_name || ""} - ` + row?.createdByUser?.id;
        }

        price = this.prepareLocaleNumber(row.sumnr);

        const preparation = [
          row.name || "-",
          row.nryear || "-",
          row.department?.name || "-",
          name,
          cpv,
          this.prepareLocaleNumber(row.totalValueWithoutTva),
          price,
          getReferenceStatusIcon(status),
        ];

        const prepareBtn = [[14]];

        if (row.procedureDocument.length) {
          const procedureDocument = row.procedureDocument[0];

          prepareBtn.push([
            4,
            "Documente",
            ["fas fa-file-plus", "font-size:2.2rem; color: #337ab7;"],
          ]);
          if (procedureDocument.status == "draft")
            prepareBtn.push([
              5,
              "Finalizează procedura",
              ["fas fa-badge-check", "font-size:2.2rem; color: green;"],
            ]);
        }

        preparation.push([prepareBtn]);

        const returnData = {
          id: row.id,
          row: preparation,
          data: row,
          _Data: row,
        };

        preparations.push(returnData);
      }
      return preparations;
    },

    reloadPage(changePage) {
      this.setNeedsTableData([]);
      this.tableData.body.splice(0);
      this.tableReload = 1;
      this.totalItemsLength = 0;
      this.totalRecords = 0;
      (this.currentPage = this.getPaginatedParams()),
        (this.dataLoading = false);
      this.tableFilter.query = "";
      this.managePages(changePage);
    },
    setPage(from, to) {
      if ((!parseInt(from) && from !== 0) || (!parseInt(to) && to !== 0)) {
        return;
      }

      this.currentPage.from = from;
      this.currentPage.to = to;

      this.managePages();
    },
    async getNeedReferenceData(showPerPage, page) {
      let prepare = [];

      this.tableFilter.baseQuery = `paapCentralization.id=${this.$route.params.centralization}`;

      let response = await REFERENCE_PAGINATED(
        page,
        showPerPage,
        this.tableFilter.baseQuery.concat(this.tableFilter.query) || false
      );

      if (Number.isInteger(response?.data?.recordsQuantity)) {
        this.totalItemsLength = response.data.recordsQuantity;
        this.tableData.options.totalItems = response.data.recordsQuantity;
      }

      prepare = this.prepareData(response.data.result);
      return prepare;
    },

    async managePages(changePage) {
      if (this.dataLoading) {
        return;
      }

      this.dataLoading = true;
      let data = this.getNeedsTableData;

      const { from, to } = this.currentPage;
      const show = to - from;
      const currentPage = from / (to - from) + 1;
      const totalItems = this.totalItemsLength;

      if (to >= totalItems && data.length && changePage !== true) {
        return;
      }

      let uploadPages = [];
      let reloadRowsAfterChangeShow = false;

      if (currentPage > 0) {
        if (!data[from]) {
          uploadPages.push(currentPage);
        } else if (data[from] && !data[to - 1]) {
          this.tableData.body = [];
          data = [];
          reloadRowsAfterChangeShow = true;
          uploadPages.push(currentPage);
        }
      }
      if (Math.ceil(totalItems / show) - currentPage > 0) {
        if (!data[to]) {
          uploadPages.push(currentPage + 1);
        }
      }
      if (currentPage - 1 >= 1) {
        if (!data[from - show]) {
          uploadPages.push(currentPage - 1);
        }
      }
      if (
        this.tableReload === 1 &&
        uploadPages.length < 2 &&
        uploadPages.indexOf(currentPage + 1) == -1
      ) {
        uploadPages.push(currentPage + 1);
      }

      if (uploadPages.length) {
        let preparation = [];
        let prepare = [];
        let i = 0;

        for (const page of uploadPages) {
          const getNeedsPage = await this.getNeedReferenceData(show, page);
          preparation.push(getNeedsPage);
          i++;
        }
        const lastRowIndex = data[data.length - 1]
          ? data[data.length - 1].index + 1
          : 0;

        preparation = preparation.flat(1);

        preparation.forEach((e, i) => {
          e.row.splice(0, 0, {
            val: lastRowIndex + (i + 1),
            border: "right",
          });
          if (
            e.data.acquisitionProduct.length == 0 &&
            e.data.procedureDocument.length == 0
          ) {
            e.row.splice(0, 0, {
              checkbox: true,
              label:
                e.data.nryear < new Date().getFullYear()
                  ? "Referaul nu mai poate fi selectat, nu se încadrează în anul curent."
                  : "",
              disabled: e.data.nryear < new Date().getFullYear(),
            });
          } else {
            e.row.splice(0, 0, { spacer: true });
          }

          prepare.push(
            Object.assign([...e.row], {
              id: e.id,
            })
          );
        });

        data.push(preparation);
        data = data.flat(1);

        data.forEach((e, i) => {
          e.NR = i;
          e.index = i;
        });

        this.setNeedsTableData(data);
        this.tableData.body =
          this.tableData.body.concat(prepare).length === 0
            ? [false]
            : this.tableData.body.concat(prepare);
        if (reloadRowsAfterChangeShow || this.tableReload == 1)
          this.tableReload++;
      } else if (!this.tableFilter.query) {
        let prepare = [];
        data.forEach((e) => {
          prepare.push(
            Object.assign([...e.row], {
              id: e.id,
            })
          );
        });
      }
      this.dataLoading = false;
    },

    openActions() {
      if (this.checkedRows.length == 0 && !this.allSelected) {
        this.$toastr.w("Nu a fost selectat nici un referat.");
        return;
      }
      this.$modal.show(
        DialogModal,
        {
          target: "ActiuniAchizitie",
          title: "Acțiuni achiziții",
          inputs: [
            {
              id: "assignmentActions",
              type: "component",
              component: AssignmentActions,
              errReason: "",
              componentProps: {
                centralization: this.centralization,
                needReferences: this.allSelected
                  ? "all"
                  : this.checkedRows.map((el) => el.id),
              },
            },
          ],
          closeBtnText: "Anulează",
          button: {
            disabled: true,
          },
          customClass: "fullWidth",
        },
        {
          name: "ActiuniAchizitie",
          adaptive: true,
          width: "650px",
          height: "570px",
        }
      );
    },

    async createProcedureDocument() {
      if (this.checkedRows.length == 0 && !this.allSelected) {
        this.$toastr.w("Nu a fost selectat nici un referat.");
        return;
      }
      this.$modal.show(
        DialogModal,
        {
          target: "CreateProcedureDocument",
          title: "Creare procedură",
          inputs: [
            {
              id: "files",
              type: "component",
              component: ProcedureDocuments,
              componentProps: { procedureDocument: null },
              errReason: "Nu a fost încărcat nici un fișier.",
            },
          ],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Procedura nu a fost creată.");
                this.setLoad();
              };

              this.setLoad(true);

              const sendData = new FormData();

              sendData.append("paapCentralization", this.centralization.id);
              sendData.append(
                "needReferences",
                this.allSelected
                  ? "all"
                  : JSON.stringify(this.checkedRows.map((el) => el.id))
              );

              data.files
                .filter((e) => !e.deleted)
                .forEach((file) => {
                  sendData.append("files", file.file);
                });

              PROCEDURE_DOCUMENTS.createProcedureDocument(sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Procedura a fost salvată.");
                    this.reloadPage();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
          customClass: "fullWidth",
        },
        {
          name: "CreateProcedureDocument",
          adaptive: true,
          width: "1000",
          height: "1000",
        }
      );
    },

    async updateProcedureDocument(index) {
      const find = this.getNeedsTableData.find((e) => e.index === index);

      const item = find.data.procedureDocument[0] || {};

      this.$modal.show(
        DialogModal,
        {
          target: "updateProcedureDocument",
          title: "Adaugare document",
          inputs: [
            {
              id: "files",
              type: "component",
              component: ProcedureDocuments,
              componentProps: { procedureDocument: item },
              errReason: "Nu a fost încărcat nici un fișier.",
            },
          ],

          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Documentele nu au fost salvate.");
                this.setLoad();
              };

              this.setLoad(true);

              const sendData = new FormData();

              data.files
                .filter((e) => !e.deleted)
                .forEach((file) => {
                  sendData.append("files", file.file);
                });

              PROCEDURE_DOCUMENTS.addDocuments(item.id, sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Documentele nu au fost adăugate.");
                    this.reloadPage();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
          customClass: "fullWidth",
        },
        {
          name: "updateProcedureDocument",
          adaptive: true,
          width: "1000",
          height: "1000",
        }
      );
    },

    closeProcedureDocument(index) {
      const find = this.getNeedsTableData.find((e) => e.index === index);

      const item = find.data.procedureDocument[0] || {};
      this.$modal.show(
        DialogModal,
        {
          target: "CloseAcquisitionDocument",
          title: "Doriți să finalizați procedura?",
          inputs: [],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Eroare la finalizarea procedurii.");
                this.reloadPage();
                this.setLoad();
              };

              this.setLoad(true);
              PROCEDURE_DOCUMENTS.close(item.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Procedura a fost finalizată.");
                    this.reloadPage();
                    this.setLoad();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "CloseAcquisitionDocument",
          adaptive: true,
          width: "550",
          height: "300",
        }
      );
    },
  },

  created() {
    GET_PAAP_CENTRALIZATION_PAGINATED(
      1,
      1,
      `eq=id,${this.$route.params.centralization}`
    ).then((res) => {
      this.centralization = res.data.result[0];
      const path = this.$route.path;
      const val = path.slice(0, path.lastIndexOf("/"));
      this.$root.$emit("select-item", val);
    });

    this.reloadPage(true);
  },
  mounted() {},
};
</script>

<style>
.fullWidth {
  max-width: 100% !important;
  max-height: 100% !important;
}
</style>
